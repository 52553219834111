import { createPopper, type Placement } from "@popperjs/core";
import { type Directive } from "vue";

type TooltipElementTrigger = HTMLElement & {
  destroyTooltip?: () => void;
};

type TooltipDirectiveValue =
  | string
  | {
      value?: string;
      html?: boolean;
      placement?: Placement;
    };

async function handleTooltipInstanceUpdate(
  element: TooltipElementTrigger,
  { value }: { value?: TooltipDirectiveValue },
) {
  await nextTick();

  const tooltipText = typeof value === "string" ? value : value?.value;
  const isHTMLContent = typeof value === "object" ? !!value?.html : false;
  const destroyAttachedTooltip = element?.destroyTooltip;

  if (!tooltipText) return destroyAttachedTooltip?.();
  if (destroyAttachedTooltip) return;

  const tooltip = document.createElement("p");

  tooltip[isHTMLContent ? "innerHTML" : "textContent"] = tooltipText;
  tooltip.classList.add(
    "bg-primary-950",
    "text-white",
    "max-w-[280px]",
    "text-xs",
    "py-1",
    "px-2",
    "rounded",
    "hidden",
    "z-[9999]",
  );
  document.body.append(tooltip);

  const popperInstance = createPopper(element, tooltip, {
    placement: typeof value === "object" ? (value.placement ?? "auto") : "auto",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
      {
        name: "preventOverflow",
        options: {
          padding: 20,
        },
      },
    ],
  });

  function show() {
    tooltip.classList.remove("hidden");

    popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...(options.modifiers ?? []),
        { name: "eventListeners", enabled: true },
      ],
    }));

    popperInstance.update();
  }

  function hide() {
    tooltip.classList.add("hidden");
    popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...(options.modifiers ?? []),
        { name: "eventListeners", enabled: false },
      ],
    }));
  }

  const showEvents = ["mouseenter", "focus"];
  const hideEvents = ["mouseleave", "blur", "click"];

  showEvents.forEach((event) => element.addEventListener(event, show));
  hideEvents.forEach((event) => element.addEventListener(event, hide));

  const destroyTooltip = () => {
    showEvents.forEach((event) => element.removeEventListener(event, show));
    hideEvents.forEach((event) => element.removeEventListener(event, hide));
    popperInstance.destroy();
    element.destroyTooltip = undefined;
  };

  Object.defineProperty(element, "destroyTooltip", {
    value: destroyTooltip,
    writable: true,
  });
}

export const tooltipDirective: Directive = {
  mounted: handleTooltipInstanceUpdate,
  updated: handleTooltipInstanceUpdate,

  unmounted(element: TooltipElementTrigger) {
    element.destroyTooltip?.();
  },
};

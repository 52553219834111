export async function downloadFromUrl(
  url: string,
  options?: {
    filename?: string;
  },
) {
  const response = await $fetch.raw(url);
  const blob = (await response._data) as Blob;

  const aElement = document.createElement("a");
  aElement.setAttribute("download", options?.filename ?? "file");
  const href = URL.createObjectURL(blob);
  aElement.href = href;
  aElement.setAttribute("target", "_blank");
  aElement.click();
  URL.revokeObjectURL(href);
  aElement.remove();
}

import { addYears } from "date-fns";

import {
  GROWTH_CONTACT_COOKIE_NAME,
  type GrowthContact,
  hasCompletePII as _hasCompletePII,
} from "~~/model/growth.model";

export const useGrowthContact = () => {
  const state = useCookie<GrowthContact | null | undefined>(
    GROWTH_CONTACT_COOKIE_NAME,
    {
      expires: addYears(new Date(), 1),
    },
  );

  const { $analytics } = useNuxtApp();

  const update = (request: GrowthContact) => {
    state.value = {
      ...state.value,
      ...request,
    };

    $analytics.identify({
      origin: request.origin,
      email: request.email,
      title: request.job_title,
      company: request.company
        ? { name: request.company, size: request.company_size }
        : undefined,
      firstName: request.first_name,
      lastName: request.last_name,
      interests: request.interests,
      phone: request.phone_number,
      additional_information: request.additional_information,
      newsletter_subscribed: request.newsletter_subscribed,

      website_contact_form: {
        submitted: request.website_contact_form?.submitted,
        referrer: request.website_contact_form?.referrer,
        origin: request.website_contact_form?.origin,
        origin_page_id: request.website_contact_form?.origin_page_id,
        origin_page_action: request.website_contact_form?.origin_page_action,
      },
    });
  };

  const hasCompletePII = computed(() =>
    state.value ? _hasCompletePII(state.value) : false,
  );

  return {
    state,
    update,
    hasCompletePII,
  };
};

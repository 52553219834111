import { dialogEventHook } from "./dialog.hook";

export function useActiveDialog() {
  const id = useState<string | undefined>("active_dialog");
  const binding = useState<Record<string, unknown> | undefined>(
    "active_dialog_binding",
  );

  dialogEventHook.open.on((event) => {
    id.value = event.id;
    binding.value = event.binding;
  });

  dialogEventHook.close.on(() => {
    id.value = undefined;
    binding.value = undefined;
  });

  return {
    id,
    binding: readonly(binding),
  };
}

export default defineNuxtRouteMiddleware(async (to) => {
  const { me, isAdmin, isAuthenticated } = useCurrentUser();
  const { $analytics } = useNuxtApp();

  const currentUser = useEnrichedCurrentUser();

  if (me.value) {
    await currentUser.ensureIsLoaded();

    if (import.meta.client && useState("identify_id").value !== me.value.id) {
      $analytics.identify(me.value.id, {
        email: me.value.email,
        signed_up: true,
        organization:
          currentUser.me.value?.organization_membership?.organization?.slug,
      });
      useState("identify_id").value = me.value.id;
    }
  } else {
    currentUser.resetState();
  }

  if (isAuthenticated.value && to.name === "auth-login") {
    return await navigateTo((to.query.redirect as string) ?? "/");
  }

  if (to.meta.requiresAuthentication && !isAuthenticated.value) {
    return await navigateTo({
      name: "auth-login",
      hash: "#signin",
      query: {
        redirect: to.fullPath,
      },
    });
  }

  if (to.meta.requiresAdminAccess && !isAdmin.value) {
    throw createError({
      statusCode: 404,
      fatal: true,
    });
  }
});

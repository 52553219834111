import { startOfTomorrow } from "date-fns";

import type { CourseSessionWithPlaceView } from "~/business-areas/course-session/course-session.model";

export type CourseListApiView = Pick<
  DatabaseTable<"courses">,
  | "id"
  | "slug"
  | "content_type"
  | "created_at"
  | "public"
  | "indexed"
  | "title"
  | "description"
  | "languages"
  | "format"
  | "video_url"
  | "cover_url"
  | "pinned"
  | "subject"
  | "certifying"
> & {
  teacher: Pick<
    DatabaseTable<"teachers">,
    | "id"
    | "full_name"
    | "job"
    | "avatar_url"
    | "slug"
    | "first_name"
    | "pinned"
    | "description_short"
    | "cover_url"
  > & {
    companies: DatabaseTable<"companies">[];
  };
  sessions: CourseSessionWithPlaceView[];
  tags: Array<
    DatabaseTable<"tags"> & Pick<DatabaseTable<"course_tags">, "order">
  >;
  ratings: [{ count: number; avg: number | null }];
};

export type CourseListView = Omit<CourseListApiView, "ratings"> & {
  ratings: { avg: number; count: number } | null;
  _links: {
    teacherPage: string;
    coursePage: string;
  };
};

export interface CourseFilters {
  excludeSlugs?: string[];
  tags?: DatabaseTable<"tags">["slug"][];
  ids?: number[];
  slug?: string;
  limit?: number;
  from?: string;
  to?: string;
  pinnedTeacher?: boolean;
  teachers?: DatabaseTable<"teachers">["id"][];
  lang?: string;
  duration?: string;
  pinned?: boolean;
  includeNonIndexed?: boolean;
}

export const useCourseList = (
  key: string,
  filters?: CourseFilters,
  options?: {
    server?: boolean;
    lazy?: boolean;
  },
) => {
  const client = useDatabaseClient();
  const courseCache = useCourseCache();

  return useAsyncData(
    key,
    async () => {
      const isDateFilterActive = filters?.from || filters?.to;

      let query = client
        .from("courses_with_next_session_start_date_view")
        .select(
          `
          id,
          created_at,
          slug,
          title,
          description,
          public,
          indexed,
          subject,
          pinned,
          languages,
          format,
          content_type,
          certifying,
          video_url,
          cover_url,
          teacher: teacher_with_companies!inner(
            id,
            first_name,
            full_name,
            job,
            companies,
            avatar_url,
            slug,
            pinned,
            description_short,
            cover_url
          ),
          ratings: course_ratings_view(
            global_satisfaction.avg(),
            global_satisfaction.count()
          ),
          sessions: course_sessions${isDateFilterActive ? "!inner" : ""}(
            *,
            place: places(*)
          ),
          tags: course_primary_tags_view!course_tags_course_id_fkey!inner(*)`,
        )
        .eq("public", true)
        .eq("sessions.is_marketing_event", false)
        .order("next_session_start_date", { nullsFirst: false })
        .order("start_date", { referencedTable: "course_sessions" })
        .order("order", { referencedTable: "course_primary_tags_view" });

      if (!isDateFilterActive) {
        query = query.gte(
          "sessions.start_date",
          startOfTomorrow().toISOString(),
        );
      }

      if (filters?.includeNonIndexed !== true) {
        query = query.eq("indexed", true);
      }

      if (filters?.to) {
        query = query.lte("sessions.start_date", filters?.to);
      }

      if (filters?.from) {
        query = query.gte("sessions.start_date", filters?.from);
      }

      if (filters?.excludeSlugs?.length) {
        query = query.not("slug", "in", `(${filters.excludeSlugs.join(",")})`);
      }

      if (filters?.tags?.length) {
        query = query.filter(
          "tags.path",
          "match",
          `^(${filters.tags.join("|")}).*`,
        );
      }

      if (filters?.ids) {
        query = query.in("id", filters.ids);
      }

      if (filters?.lang) {
        query = query.overlaps("languages", [filters.lang]);
      }

      if (filters?.duration) {
        query = query.eq("format->duration", `"${filters.duration}"`);
      }

      if (filters?.limit) {
        query = query.limit(filters.limit);
      }

      if (filters?.pinned) {
        query = query.eq("pinned", true);
      }

      if (filters?.slug !== undefined) {
        query = query.eq("slug", filters.slug);
      }

      if (filters?.pinnedTeacher) {
        query = query.eq("teacher.pinned", true);
      }

      if (filters?.teachers) {
        query = query.in("teacher_id", filters.teachers);
      }

      const { data } = await query.returns<CourseListApiView[]>();

      return data ?? [];
    },
    {
      transform(data) {
        const views = data.map((apiView) => {
          const ratings = apiView.ratings[0];
          const view: CourseListView = {
            ...apiView,
            ratings:
              ratings.avg !== null && ratings.count > 0
                ? {
                    avg: ratings.avg,
                    count: ratings.count,
                  }
                : null,
            _links: {
              teacherPage: `/trainers/${apiView.teacher.slug}`,
              coursePage: `/courses/${apiView.slug}`,
            },
          };

          return view;
        });

        courseCache.setMultiple(views);

        return views;
      },
      default: () => [] as CourseListView[],
      watch: filters ? Object.values(toRefs(filters)) : undefined,
      server: options?.server ?? true,
      lazy: options?.lazy,
    },
  );
};

import type { CoursePageView } from "~/business-areas/course/course.model";

export function useCoursePageProgramDownload(
  course: Pick<DatabaseTable<"courses">, "slug" | "id" | "title">,
) {
  const { update, state } = useGrowthContact();
  const { exists } = useGrowthContactFormHistory();

  const hasAlreadyDownloadedProgram = computed(
    () =>
      exists({
        email: state.value?.email ?? "",
        origin: "course",
        origin_page_id: course.slug,
        origin_page_action: "download_program_cta",
      }) ||
      exists({
        email: state.value?.email ?? "",
        origin: "course_list",
        origin_page_id: course.slug,
        origin_page_action: "download_program_cta",
      }),
  );

  async function download() {
    try {
      const url = await $fetch(
        `/api/courses/${course.id}/program-document-url`,
      );

      if (url) {
        showSuccessToastNotification(
          "Votre programme est en cours de téléchargement !",
          {
            icon: "ph:file-arrow-down",
          },
        );

        downloadFromUrl(url, {
          filename: `${course.title} - Pollen`,
        });
      } else {
        update({
          website_contact_form: {
            origin: "course",
            origin_page_id: course.slug,
            origin_page_action: "follow_up_program_cta",
          },
        });

        showSuccessToastNotification(
          "Notre équipe vous tiendra informé.e dans les plus brefs délais",
        );
      }
    } catch (error) {
      useMonitoring().logError(error as Error);
    }
  }

  return { download, hasAlreadyDownloadedProgram };
}

export function useCoursePageProgramDownloadOnPageInit(course: CoursePageView) {
  if (import.meta.server) return;

  const query = useRoute().query;

  if (!query.download_program) return;

  useCoursePageProgramDownload(course).download();

  return useRouter().push({
    query: {
      ...query,
      download_program: undefined,
    },
  });
}

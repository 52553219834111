<template>
  <app-button
    v-bind="$attrs"
    :block="block"
    :color="color"
    :icon="icon"
    :size="size"
    :to="target"
    :variant="variant"
    @click="onClick"
  >
    <slot> {{ $t("growth.contact_button.default_label") }} </slot>
  </app-button>
</template>

<script lang="ts" setup>
import type { ButtonProps } from "@pollen/ui-library";

import { type RouteLocationRaw } from "#vue-router";
import type { ContactFlowMetadata } from "~~/model/analytics.model";

defineOptions({
  inheritAttrs: false,
});

const properties = withDefaults(
  defineProps<
    ButtonProps &
      ContactFlowMetadata & {
        redirect?: string;
        confirmationToast?: boolean;
        skipFormIfKnownUser?: boolean;
      }
  >(),
  {
    redirect: undefined,
    confirmationToast: true,
  },
);

const emit = defineEmits<{
  skipped: [];
}>();

const { $analytics } = useNuxtApp();
const { hasCompletePII, update, state } = useGrowthContact();
const { isAuthenticated, me } = useCurrentUser();
const { record } = useGrowthContactFormHistory();
const route = useRoute();

const skipForm = computed(() => {
  return (
    (isAuthenticated.value || hasCompletePII.value) &&
    properties.skipFormIfKnownUser
  );
});

const target = computed<RouteLocationRaw | undefined>(() => {
  if (skipForm.value) return;

  return {
    name: "contact",
    query: {
      origin: properties.origin,
      origin_page_action: properties.origin_page_action,
      origin_page_id: properties.origin_page_id,
      redirect: properties.redirect,
      confirmation_toast: `${properties.confirmationToast ?? true}`,
    },
  };
});

function onClick() {
  if (skipForm.value) {
    record({
      email: me.value?.email ?? state.value?.email ?? "",
      origin: properties.origin,
      origin_page_id: properties.origin_page_id,
      origin_page_action: properties.origin_page_action,
    });

    update({
      website_contact_form: {
        submitted: true,
        origin: properties.origin,
        origin_page_id: properties.origin_page_id,
        origin_page_action: properties.origin_page_action,
        referrer: route.path,
      },
    });

    return emit("skipped");
  }

  $analytics.track("call_flow_started", {
    origin: properties.origin,
    origin_page_id: properties.origin_page_id,
    origin_page_action: properties.origin_page_action,
  });
}
</script>

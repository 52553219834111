import type { RouteLocationRaw } from "#vue-router";
import type { CourseListView } from "~/business-areas/course/composables/course-list.hook";
import type { TagPageApiView } from "~~/model/tag-page.model";

export type TagPageView = TagPageApiView;

type TagPageCourseSection = {
  id: string | number;
  title: string;
  subtitle?: string;
  list: CourseListView[];
  to?: RouteLocationRaw;
};

export function tagPageCacheKey(slug: string) {
  return `tag_page_${slug}`;
}

function displayCourseInSection(
  course: CourseListView,
  section: TagPageView["sections"][number],
) {
  const courseTagsPaths = course.tags.map((tag) => tag.path);
  const sectionTagsPaths = section.tags.map((tag) => tag.path);

  return courseTagsPaths.some((cPath) =>
    sectionTagsPaths.some((sPath) => cPath.startsWith(sPath)),
  );
}

export function useTagPageView(slug: string) {
  const query = useLazyFetch(`/api/tag-pages/${slug}`, {
    key: tagPageCacheKey(slug),
    getCachedData(key) {
      return useNuxtData(key).data.value;
    },
  });

  const { data: courses, status: coursesStatus } = useCourseList(
    `tag_page_courses_${slug}`,
    {
      tags: [slug],
    },
    { lazy: true },
  );

  const sections = computed<TagPageCourseSection[]>(() => {
    if (query.data.value?.sections.length === 0 && courses.value.length > 0) {
      return [
        {
          id: "all",
          title: `<span class="font-normal">Formations -</span> ${query.data.value?.tag.label}`,
          list: courses.value,
        },
      ];
    }

    const customSections = (query.data.value?.sections ?? []).map((section) => {
      return {
        id: section.id,
        title: section.title,
        subtitle: section.description ?? undefined,
        list: courses.value.filter((course) =>
          displayCourseInSection(course, section),
        ),
      };
    });

    const leftoverCoursesSection: TagPageCourseSection = {
      id: "others",
      title: `Autre formations - <span class="font-normal">${query.data.value?.tag.label}</span>`,
      list: courses.value.filter((course) => {
        return customSections.every((section) =>
          section.list.map((c) => c.id).every((id) => course.id !== id),
        );
      }),
    };

    return [...customSections, leftoverCoursesSection].filter(
      (section) => section.list.length > 0,
    );
  });

  whenever(
    query.error,
    (error) => {
      throw createError({
        ...error,
        fatal: true,
      });
    },
    { immediate: true },
  );

  return {
    ...query,
    courses,
    sections,
    pending: computed(
      () =>
        coursesStatus.value === "pending" ||
        (query.status.value === "pending" && !query.data.value),
    ),
  };
}

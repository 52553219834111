import { tooltipDirective } from "../shared/tooltip/tooltip.directive";

const SMOOTH_SCROLL_OFFSET_IN_PX = 20;

function clickHandler(event: MouseEvent) {
  event.preventDefault();

  const href = (event.target as HTMLElement).closest("a")?.getAttribute("href");
  if (!href || !href.startsWith("#")) return;

  const element = document.querySelector<HTMLElement>(href);
  if (!element) return;

  const position = element.getBoundingClientRect();

  scrollTo({
    top:
      position.top +
      (document.scrollingElement?.scrollTop ?? 0) -
      SMOOTH_SCROLL_OFFSET_IN_PX,
    behavior: "smooth",
  });
}

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.directive("smooth-scroll", {
    mounted(el) {
      if (el.destroyAttachedDirective) return;

      function destroyAttachedDirective() {
        el.removeEventListener("click", clickHandler);
      }

      el.addEventListener("click", clickHandler);
      Object.defineProperty(el, "destroyAttachedDirective", {
        value: destroyAttachedDirective,
      });
    },
    unmounted(el) {
      el.destroyAttachedDirective?.();
    },
  });

  vueApp.directive("tooltip", tooltipDirective);
});

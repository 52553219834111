import type { EnrichedUser } from "~~/model/user.model";

const ENRICHED_USER_QUERY = `
  *,
  profile: user_profiles(*),
  preferences: user_preferences(*),
  customer: user_customers(...customers(*)),
  sessions: course_session_attendees(
    ...course_sessions(*, place: places(address), course: courses(slug))
  ),
  certificates(*),
  organization_membership: organization_members(
    *,
    organization: organizations(*),
    requests: course_session_registration_requests(*, session: course_sessions(*, place: places(*))),
    user: users(*)
  )
`;

export const useEnrichedCurrentUser = () => {
  const client = useDatabaseClient();
  const { me } = useCurrentUser();

  const currentUserState = useState<EnrichedUser | null | undefined>(
    "current_user_state",
  );

  async function refresh() {
    if (!me.value) {
      currentUserState.value = undefined;

      return;
    }

    const { data } = await client
      .from("users")
      .select(ENRICHED_USER_QUERY)
      .eq("id", me.value.id)
      .single();

    currentUserState.value = data as unknown as EnrichedUser;
  }

  const ensureIsLoaded = async () => {
    if (currentUserState.value || currentUserState.value === null || !me.value)
      return;

    return refresh();
  };

  const resetState = () => {
    currentUserState.value = undefined;
  };

  const update = async (request: DatabaseUpdateRequest<"users">) => {
    try {
      await $fetch("/api/users/me", {
        method: "PUT",
        body: request,
      });

      return refresh();
    } catch {
      // Error will be logged on server
    }
  };

  async function updatePreferences(
    request: DatabaseUpdateRequest<"user_preferences">,
  ) {
    try {
      await $fetch("/api/users/me/preferences", {
        method: "PUT",
        body: request,
      });

      return refresh();
    } catch {
      // Error will be logged on server
    }
  }

  async function updateProfile(
    request: DatabaseUpdateRequest<"user_profiles">,
  ) {
    try {
      await $fetch("/api/users/me/profile", {
        method: "PUT",
        body: request,
      });

      return refresh();
    } catch {
      // Error will be logged on server
    }
  }

  const hasCompletePersonalInformation = computed(() => {
    return (
      currentUserState.value?.company_name &&
      currentUserState.value?.job_title &&
      currentUserState.value?.phone_number
    );
  });

  return {
    me: readonly(currentUserState),
    refresh,
    ensureIsLoaded,
    resetState,
    update,
    updateProfile,
    updatePreferences,
    hasCompletePersonalInformation,
  };
};

<template>
  <div class="grid">
    <div
      class="relative grid translate-y-1 grid-rows-[1fr_auto] justify-between transition duration-300 group-hover:translate-y-0"
    >
      <nuxt-link
        class="flex flex-col justify-between gap-4 p-4 pb-0"
        :to="course._links.coursePage"
      >
        <div v-if="mainTag" class="flex items-center gap-2">
          <app-icon
            class="rounded p-1"
            :icon="mainTag.icon"
            :size="38"
            :style="{ backgroundColor: mainTagColors?.background }"
          />
          <p class="text-sm font-semibold">
            {{ mainTag.label }}
          </p>
        </div>
        <h3 class="line-clamp-4 text-xl/snug font-bold">
          {{ course.title }}
        </h3>
        <span />
      </nuxt-link>
      <div class="grid gap-3 p-4 pt-0">
        <growth-contact-button
          block
          color="dark"
          :confirmation-toast="false"
          icon="ph:download-simple"
          origin="course_list"
          origin_page_action="download_program_cta"
          :origin_page_id="course.slug"
          :redirect="redirect"
          skip-form-if-known-user
          @skipped="download"
        >
          Programme
        </growth-contact-button>
        <app-button
          block
          color="dark"
          :to="course._links.coursePage"
          variant="outlined"
        >
          Voir le détail
        </app-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CourseListView } from "~/business-areas/course/composables/course-list.hook";

const properties = defineProps<{
  course: CourseListView;
  mainTag?: DatabaseTable<"tags">;
  mainTagColors?: {
    border: string;
    background: string;
    text: string;
  };
}>();

const router = useRouter();
const redirect = computed(
  () =>
    `${router.resolve(properties.course._links.coursePage).path}?download_program=true`,
);

const { download } = useCoursePageProgramDownload(properties.course);
</script>
